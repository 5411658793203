import * as Sentry from "@sentry/react";

const initSentry = (): void => {
    if (import.meta.env.VITE_APP_SENTRY_URL) {
        Sentry.init({
            dsn: import.meta.env.VITE_APP_SENTRY_URL,
            integrations: [Sentry.browserTracingIntegration()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0
        });
    }
};

export default initSentry;
